<!--
File: EditFields.vue
Description: Component returning formatted editing fields.
-->
<template>
  <div class="md-layout">
    <div v-for="field in fields" :key="field.name" :class="field.class">
      <md-field v-if="field.type !== 'custom'" :class="getClass(field.name)">
        <label v-if="field.label" for="theField">{{ $t(field.label) }}</label>
        <component id="theField" :is="componentMap[field.type] || 'md-input'" :type="numberInput[field.type] || null"
          v-model="localValues[field.name]" :data-vv-name="field.name" v-validate="validations[field.name]"
          v-bind="field.props" />
      </md-field>

      <!-- Если поле кастомное -->
      <component v-else :class="getClass(field.name)" :label="$t(field.label)" v-model="localValues[field.name]"
        :data-vv-name="field.name" v-validate="validations[field.name]" v-bind="field.props" :is="field.component"
        @input="(val) => updateValue(field.name, val)" />
    </div>
  </div>
</template>

<script>
import permissions from "@/mixins/permissionsMixin"   // needed for getClass method
import { RegionsDropdown, RoadsDropdown, DepsDropdown, RdbLookupDropdown, TreatmentsDropdown } from '@/pages/Components'

export default {
  name: 'edit-fields',
  mixins: [permissions],

  data() {
    return {
      componentMap: {
        text: "md-input",
        textarea: "md-textarea",
        select: "md-select"
      },
      numberInput: {
        number: "number"
      }
    };
  },

  props: {
    fields: Array,
    value: Object,
    validations: Object
  },

  components: {
    RegionsDropdown,
    RoadsDropdown,
    DepsDropdown,
    RdbLookupDropdown,
    TreatmentsDropdown
  },

  methods: {
    updateValue(fieldName, value) {
      this.$set(this.localValues, fieldName, value);
    },

    async validateForm() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    }
  },

  computed: {
    localValues: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
};
</script>
