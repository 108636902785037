<!--
File: BaseTable.vue
Description: base component for showing tables.
-->
<template>
  <div>
    <md-progress-spinner v-if="loading" :md-diameter="100" md-mode="indeterminate" />

    <md-table class="paginated-table table-striped table-hover" :value="items" :md-selected-value.sync="localValue"
      :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
      
      <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />

      <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
        :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null"
        v-bind="isSelectable ? { 'md-selectable': 'multiple', } : {}" md-auto-select>

        <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.label)"
          :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
          <template v-if="header.condition">
            <a href='' @click.stop.prevent="header.onClick(item[header.clickField])"> {{ item[header.value] }} </a>
          </template>
          <template v-else>
            {{ item[header.value] }}
          </template>
        </md-table-cell>

        <md-table-cell :md-label="$t('tables.actions')">
          <slot name="table-actions" :item="item"></slot>
        </md-table-cell>
        <md-table-cell v-if="headers.length <= 1" :md-label="''" />
      </md-table-row>
    </md-table>
    <slot name="table-footer"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { customSortMixin } from '@/mixins/customSortMixin';

export default {
  name: 'base-table',
  mixins: [customSortMixin],

  props: {
    label: { default: '', type: String },
    value: { default: () => [], type: Array },
    disabled: { default: false, type: Boolean },
    loading: { default: false, type: Boolean },
    isSelectable: { default: false, type: Boolean },
    items: { default: () => [], type: Array },
    headers: { default: () => [], type: Array },
    idFieldName: { default: '', type: String },
    sort: { default: '', type: String },
    sortOrder: { default: 'asc', type: String },
  },

  data() {
    return {
      currentSort: this.sort,
      currentSortOrder: this.sortOrder,
    }
  },

  async mounted() {
    console.log('idFieldName, highlightedRow', this.idFieldName, this.highlightedRow)
  },

  methods: {
  },

  computed: {
    ...mapState({
      highlightedRow: (state) => state.History.row
    }),

    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal); // Emit event for v-model
      },
    },
  },

  watch: {
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';

.paginated-table table>tbody>tr>td {
  padding: 5px !important;
  outline: 0;
  max-width: 150px;
  width: auto;
  border-right: 0;
  border-bottom: 0;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 25%;
  z-index: 20;
}
</style>