<!--
File: WorkCategoriesDropdown.vue
Description: show the dropdown combo with the Work Categories list in the TreatmentsTypes.vue and TreatmentTypeEditForm.vue.
-->
<template>
  <base-dropdown
    v-model="selectedValue"
    :label="label || $t('label.category')"
    :items="categoriesList"
    :isRequired="isRequired"
    :disabled="disabled"
    :dropdownId="'workCategoryDropdown'"
    @input="onChange"
  />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseDropdown from './BaseDropdown.vue'

export default {
  name: 'work-categories-dropdown',

  props: {
    label: { default: null, type: String },
    value: { default: null, type: Number },
    isRequired: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    routine: { default: 0, type: Number }
  },

  data() {
    return {
      selectedValue: this.value || null,
      listLoaded: false
    }
  },

  components: {
    BaseDropdown,
  },

  async mounted() {
    await this.reloadData();
  },

  methods: {
    ...mapActions({
      getList: 'GET_WORK_CATEGORIES_LIST'
    }),

    onChange(value, description) {
      this.$emit('input', value, description);
    },

    async reloadData() {
      this.listLoaded = false;
      try {
      //  this.selectedValue = null;
        const res = await this.getList(this.routine);
      /*  if (res === 'success') {
          if (this.value != null && !this.selectedValue) this.selectedValue = this.value;
        }*/
      } finally {
        this.listLoaded = true;
      }
    },
  },

  computed: {
    ...mapState({
      categoriesList: (state) => state.Dropdowns.work_categories
    })
  },

  watch: {
    value(newValue) {
      if (newValue !== this.selectedValue) {
        this.selectedValue = newValue;
      }
    },

    routine: 'reloadData', // Watch for changes in routine
  }
}
</script>